import { CONTENT_TYPES } from '../constants/contentTypes';
const ENV = window.__TBX_ENV__;

function getEnvConfig(currentENV) {
  if (
    !currentENV.TBX_CLIENT_KEY ||
    !currentENV.TBX_CLOUDPASS_API ||
    !currentENV.TBX_UNITY_API
  ) {
    console.error(
      '>>>>>> Invalid __TBX_ENV__ definition! check env variables build process',
    );
    return null;
  }

  const IDP_CLIENT = currentENV.TBX_IDP_CLIENT || null;
  const IDP_SHORTCODE = currentENV.TBX_IDP_SHORTCODE || null;
  const useCloudhubConfig = !IDP_CLIENT || !IDP_SHORTCODE;

  return {
    client: {
      SUB: currentENV.TBX_CLIENT_KEY,
      CONFIG_URI: currentENV.TBX_EXPERIENCE_CONFIG,
    },
    cloudpass: {
      API_URL: currentENV.TBX_CLOUDPASS_API,
      IDP_CLIENT,
      IDP_SHORTCODE,
      USE_CLOUDHUB_CONFIG: currentENV.TBX_IDP_USE_CLOUDHUB || useCloudhubConfig,
    },
    unity: {
      API_URL: currentENV.TBX_UNITY_API,
    },
    settings: {
      hispanomedios: {
        useDirectAccessToAddOns: true,
        hasDirectAccessToAddOns: true,
        hasPrivacyPolicyLink: true,
        hasPayService: true,
        showContentDescription: false,
        hasCustomHelpSlug: false,
        searchFilters: {
          contentType: `${CONTENT_TYPES.SPORT},${CONTENT_TYPES.BROADCAST},${CONTENT_TYPES.MOVIE},${CONTENT_TYPES.SERIE}`,
        },
      },
      tbxgo: {
        useDirectAccessToAddOns: false,
        hasDirectAccessToAddOns: false,
        hasPrivacyPolicyLink: true,
        hasPayService: true,
        showContentDescription: false,
        hasCustomHelpSlug: false,
        searchFilters: {
          contentType: `${CONTENT_TYPES.SPORT},${CONTENT_TYPES.BROADCAST},${CONTENT_TYPES.MOVIE},${CONTENT_TYPES.SERIE}`,
        },
      },
      mio: {
        useDirectAccessToAddOns: false,
        hasDirectAccessToAddOns: false,
        hasPrivacyPolicyLink: false,
        hasPayService: false,
        showContentDescription: true,
        hasCustomHelpSlug: true,
        searchFilters: {
          contentType: `${CONTENT_TYPES.MOVIE},${CONTENT_TYPES.SERIE}`,
        },
      },
      sportshub: {
        useDirectAccessToAddOns: false,
        hasDirectAccessToAddOns: false,
        hasPrivacyPolicyLink: true,
        hasPayService: true,
        showContentDescription: false,
        hasCustomHelpSlug: false,
        searchFilters: {
          contentType: `${CONTENT_TYPES.SPORT},${CONTENT_TYPES.BROADCAST},${CONTENT_TYPES.MOVIE},${CONTENT_TYPES.SERIE}`,
        },
      },
      winsports: {
        useDirectAccessToAddOns: false,
        hasDirectAccessToAddOns: false,
        hasPrivacyPolicyLink: true,
        hasPayService: true,
        showContentDescription: false,
        hasCustomHelpSlug: false,
        searchFilters: {
          contentType: `${CONTENT_TYPES.SPORT},${CONTENT_TYPES.BROADCAST},${CONTENT_TYPES.MOVIE},${CONTENT_TYPES.SERIE}`,
        },
      },
      default: {
        useDirectAccessToAddOns: false,
        hasDirectAccessToAddOns: false,
        hasPrivacyPolicyLink: true,
        hasPayService: true,
        showContentDescription: false,
        hasCustomHelpSlug: false,
        searchFilters: {
          contentType: `${CONTENT_TYPES.SPORT},${CONTENT_TYPES.BROADCAST},${CONTENT_TYPES.MOVIE},${CONTENT_TYPES.SERIE}`,
        },
      },
    },
  };
}

const CONFIG = getEnvConfig(ENV);

export default CONFIG;
