import {
  EPGGrid,
  Player,
  WithTBXPlayerScripts,
} from '@tbx/experience-widgets-lib';
import _ from 'lodash';
import React from 'react';
import { isDesktop } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import useWidgetTranslation from '../../../customHooks/useWidgetTranslation';

import { useTranslation } from 'react-i18next';
import { SectionErrorBoundary } from '../../../HOCs/WithErrorBoundary';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Seo from '../../../components/Seo';
import { CONTENT_TYPES } from '../../../constants/contentTypes';
import { ERROR_CODE_FORMAT } from '../../../constants/errorCode';
import { GA_SECTION_NAMES } from '../../../constants/googleAnalyticEventTypes';
import * as customHooks from '../../../customHooks/usePlayerPreferences';
import useRouter from '../../../customHooks/useRouter';
import {
  getEpgChannelName,
  validateAccessToContentByUrns,
} from '../../../utils/ContentUtils';
import classNames from '../../../utils/classnameUtils';
import { ENVIROMENTS } from '../../../utils/envUtils';
import { setGoogleAnalyticEvents } from '../../../utils/googleAnalyticUtils';
import { checkifHaveContentAccess } from '../../../utils/validationUserUtils';
import { usePlayerCallbackState } from '../../App/playerCallbackContextProvider.js';
import { PATH_ERROR } from '../../AppSectionRouter/constants';
import NotAvailable from './NotAvailable';
import { clearErrors } from './actions';
import { LIVE_PLAYER, TITLES } from './constants';
import * as hooks from './hooks';
import * as selectors from './selectors';
import './styles.scss';
import useBrandInfo from '../../../customHooks/useBrandInfo.js';

function PlayerEpgSection({
  content,
  isLoading,
  unityToken,
  playerScriptsLoaded,
  playerScriptsError,
}) {
  const router = useRouter();
  const dispatch = useDispatch();
  const playerCallbackState = usePlayerCallbackState();
  const { t } = useTranslation();
  const BRAND_INFO = useBrandInfo();

  const translationTexts = {
    ...useWidgetTranslation(TITLES),
    live_player: t(LIVE_PLAYER),
  };
  const playerID = _.uniqueId('playerEPG-');
  const PLAYEREPG_PLAYER_REF = React.useRef();
  const PLAYEREPG_EPG_REF = React.useRef();
  const daysBefore = 2;
  const daysAfter = 4;
  const currentPlayer = window.videojs && window.videojs.getAllPlayers()[0];
  // #region /////// SELECTORS ///////
  const { appId, packageUpgradeMessages } = useSelector((state) =>
    selectors.selectAppSettings(state),
  );
  const selectedContent = useSelector((state) =>
    selectors.selectContent(state),
  );
  const selectedEpgList = useSelector((state) =>
    selectors.selectEpgList(state),
  );
  const playerIsFetching = useSelector((state) =>
    selectors.selectIsFetching(state),
  );
  const authenticated = useSelector((state) =>
    selectors.selectAuthenticated(state),
  );
  const selectError = useSelector((state) => selectors.selectError(state));
  const useLoadWidgetError = hooks.useLoadWidgetError(
    playerScriptsError,
    selectError,
  );

  const selectUserUrns = useSelector((state) =>
    selectors.selectUserUrns(state),
  );
  const PATHS = useSelector((state) => selectors.selectPathList(state));
  // #endregion

  // #region ///// HOOK EFFECTS /////
  hooks.useDidMount(content, unityToken, selectError, daysBefore, daysAfter);
  hooks.usePlayerCallbackConfig(
    playerCallbackState,
    PLAYEREPG_PLAYER_REF,
    PLAYEREPG_EPG_REF,
  );
  hooks.getUrnsByUser(authenticated, selectUserUrns, unityToken);

  const [setContent, contentState] = hooks.useSetContent();
  customHooks.usePlayerPreferencesInit(currentPlayer, true);
  customHooks.usePlayerPreferencesUpdate(currentPlayer, true);
  // #endregion

  React.useEffect(() => {
    const { CP006 } = ERROR_CODE_FORMAT;

    switch (selectError?.code) {
      case CP006:
        break;
      default:
        break;
    }
  }, [selectError]);

  const hasAccessToContentByUrns = () => {
    return validateAccessToContentByUrns(selectedContent?.urn, selectUserUrns);
  };

  React.useEffect(() => {
    if (!hasAccessToContentByUrns()) {
      playerCallbackState.clearPlayerCallback();
    }
  }, [selectedContent]);

  const onBack = React.useCallback(async () => {
    if (!PLAYEREPG_PLAYER_REF?.current) return;
    PLAYEREPG_PLAYER_REF.current.style.visibility = 'hidden';
    PLAYEREPG_PLAYER_REF.current.style.opacity = '0';
    if (router?.location?.state?.oneBack?.url) {
      return router.push(router?.location?.state?.oneBack?.url);
    } else {
      return router.push(PATHS.home);
    }
  }, [router]);

  const catchError = (error) => {
    return router.push(PATH_ERROR, { error, navBar: true });
  };

  const onClickContentSelected = async ({
    epgItemCollection,
    selectedProgramItemR,
  }) => {
    if (selectError?.code) {
      dispatch(clearErrors());
    }

    if (
      playerCallbackState?.state?.contentType !== CONTENT_TYPES.BROADCAST ||
      selectError?.code
    ) {
      playerCallbackState.clearPlayerCallback();
    }
    if (
      epgItemCollection.hasCatchUp &&
      selectedProgramItemR?.startTime &&
      selectedProgramItemR?.endTime
    ) {
      setContent({
        ...epgItemCollection,
        startTime: selectedProgramItemR.startTime,
        endTime: selectedProgramItemR.endTime,
      });
    } else {
      setContent(epgItemCollection);
    }
  };

  if (!selectedContent || isLoading) {
    return (
      <div className="playerEpgSectionContainer_loading">
        <LoadingSpinner className="playerEpgSectionContainer_spineer" />
      </div>
    );
  }

  const handleChangeSelectItem = ({ selectedEpgId, selectedProgramItem }) => {
    const title =
      selectedProgramItem &&
      `${BRAND_INFO.CLIENT_NAME} - ${t(
        GA_SECTION_NAMES.EPG,
      )} - ${getEpgChannelName(selectedEpgId, selectedEpgList)}`;
    title && setGoogleAnalyticEvents({ title });
  };

  const getUrnsDataForConfig = () => {
    if (!hasAccessToContentByUrns()) {
      return packageUpgradeMessages?.find((r) => {
        return r.urn === selectedContent?.urn && !!r.url;
      });
    }
    return {};
  };

  return (
    <div className="playerEpgSectionContainer">
      {selectedContent && (
        <Seo
          title={`${BRAND_INFO.CLIENT_NAME} - ${t(GA_SECTION_NAMES.EPG)} - ${
            selectedContent?.title
          }`}
        />
      )}
      <div
        className={classNames(
          'playerEpgSectionPlayer',
          !playerIsFetching &&
            playerScriptsLoaded &&
            selectedContent &&
            !selectError?.code &&
            !selectError?.message &&
            isDesktop &&
            checkifHaveContentAccess(authenticated, selectedContent) &&
            'playerEpgSectionPlayer_load',
        )}
        ref={PLAYEREPG_PLAYER_REF}
      >
        {hasAccessToContentByUrns() && !selectError?.code ? (
          <Player
            content={selectedContent}
            changeContent={contentState}
            onClickBackButton={() => onBack()}
            playerHandleError={useLoadWidgetError}
            catchError={catchError}
            translationTexts={translationTexts}
            fullWindow={false}
            eventListenerAccion={false}
            playerCallback={playerCallbackState}
            LoadingComponent={({ className, ...props }) => (
              <LoadingSpinner
                className={
                  'playerEpgSectionContainer_spineer_player ' + className
                }
                {...props}
              />
            )}
            isExitButton={false}
            options={{
              ...(appId ? { appId } : {}),
              playerID,
              jwtToken: `${unityToken.auth_type} ${unityToken.access_token}`,
              fill: true,
              responsive: true,
              autoplay: true,
              pictureInPictureToggle: true,
              environment: ENVIROMENTS,
            }}
          />
        ) : (
          selectedContent && (
            <NotAvailable
              translationTexts={translationTexts}
              checkifHaveContentAcces={checkifHaveContentAccess(
                authenticated,
                selectedContent,
              )}
              hasAccesToContent={hasAccessToContentByUrns()}
              isDesktop={isDesktop}
              selectError={selectError}
              pathLogin={PATHS.login}
              getUrnsDataForConfig={getUrnsDataForConfig()}
            />
          )
        )}
      </div>
      <div className="playerEpgSectionEpg" ref={PLAYEREPG_EPG_REF}>
        {selectedEpgList?.length > 0 && (
          <EPGGrid
            content={selectedEpgList}
            translationTexts={translationTexts}
            daysAfter={daysAfter}
            daysBefore={daysBefore}
            daysMaxRange={15}
            displayedItemsNum={3}
            displayedItemHeightInPx={150}
            imageMode={'THUMB'}
            isLoading={isLoading}
            locale={'es'}
            onChangeDayCallback={(dayItem) =>
              console.log('>> Selected dayItem:', dayItem)
            }
            onSelectedProgramItemCallback={(programItem) =>
              handleChangeSelectItem(programItem)
            }
            title={'Title'}
            unityToken={unityToken}
            onClickContentSelected={onClickContentSelected}
            showPlayIcon={false}
            enableEpgDropdown={false}
            centeredMode={true}
          />
        )}
      </div>
    </div>
  );
}

export default SectionErrorBoundary(WithTBXPlayerScripts(PlayerEpgSection));
