import { PROFILE_NAMES } from '../constants/contentTypes';
import {
  MAX_RATING_DEFAULT,
  PROFILE_TYPES,
} from '../containers/ProfileManager/constants';

/**
 * Get default admin profile by created date ASC with max_rating = publicProfileRatingLevel or 3 (Adult)
 * @param {Object} profiles
 */
export const getDefaultAdminProfile = (
  profiles = [],
  publicProfileRatingLevel = undefined,
) => {
  let defaultProfile = profiles.find(
    (p) =>
      p.max_rating ===
        (publicProfileRatingLevel.toString() || MAX_RATING_DEFAULT.ADULT) ||
      (!p.max_rating && p.props.isAdmin === true),
  );

  if (!defaultProfile) {
    defaultProfile = profiles.find(
      (p) => p.max_rating === MAX_RATING_DEFAULT.ADULT_XXX,
    );
  }

  return defaultProfile || (profiles && profiles[0]);
};

/**
 * Get current profile's avatar
 * @param {*} avatarCollection
 * @param {*} currentProfile
 *
 * @return {Object}  avatar's data
 */
export const getCurrentAvatar = (
  avatarCollection,
  profileCollection,
  currentProfile,
) => {
  const avatarId = getCurrentProfileData(profileCollection, currentProfile)
    ?.props.avatarId;
  return avatarCollection?.find((avatar) => avatar.id === avatarId);
};

/**
 * Get current profile's data
 * @param {*} profileCollection
 * @param {*} currentProfile
 *
 * @return {Object}  current profile's data
 */
export const getCurrentProfileData = (profileCollection, currentProfile) => {
  return profileCollection?.find((profile) => profile.id === currentProfile);
};

/**
 * Get avatar's data in collection
 * @param {*} avatarCollection
 * @param {*} avatarID
 *
 * @return {Object}  avatar's data
 */
export const getAvatarInCollectionById = (avatarCollection, avatarID) => {
  return avatarCollection?.find((avatar) => avatar.id === avatarID);
};

/**
 * validate profile operations
 * @param {*} profileCollection
 * @param {*} maxRatingProfile
 *
 * @return {Boolean}
 */
export const validateProfileOperations = (
  profileCollection,
  maxRatingProfile,
) => {
  const profileTypes = countProfileTypes(profileCollection);

  if (
    profileTypes.kidCount === 1 &&
    profileTypes.adultCount === 1 &&
    maxRatingProfile >= MAX_RATING_DEFAULT.ADULT
  ) {
    return false;
  }

  if (
    profileTypes.adultCount < 2 &&
    maxRatingProfile >= MAX_RATING_DEFAULT.ADULT
  ) {
    return false;
  }

  return true;
};

/**
 * Count profile types
 * @param {*} profileCollection
 *
 * @return {Object}
 */
export const countProfileTypes = (profileCollection) => {
  const profileTypes = {
    kidCount: 0,
    adultCount: 0,
  };

  profileCollection.forEach((p) =>
    p.max_rating < MAX_RATING_DEFAULT.ADULT
      ? (profileTypes.kidCount += 1)
      : (profileTypes.adultCount += 1),
  );

  return profileTypes;
};

/**
 * validate admin profile
 * @param {*} max_rating
 *
 * @return {Boolean}
 */
export const isAdminProfile = (max_rating) => {
  return max_rating >= MAX_RATING_DEFAULT.ADULT;
};

/**
 * validate if exit a admin profile
 * @param {*} profileCollection
 *
 * @return {Boolean}
 */
export const existAdminProfile = (profileCollection) => {
  return !!profileCollection?.find(
    (profile) => profile.max_rating >= MAX_RATING_DEFAULT.ADULT,
  );
};

/**
 * Get Profiles without default
 * @param {*} profilesResult
 *
 * @return {Array}
 */
export const getProfilesWithOutDefault = (profilesResult) => {
  return profilesResult?.filter(
    (profile) => profile.name !== PROFILE_NAMES.DEFAULT,
  );
};

/**
 * Validate if contentId exists in Favorites
 * @param {*} favorites
 * @param {*} contentId
 *
 * @return {Boolean}
 */
export const validateFavoriteContent = (favorites, contentId) => {
  return favorites?.includes(contentId);
};

/**
 * Get profile type name from constant
 * @param {*} maxRatingForm
 * @return {String}
 */
export const getProfileTypeName = (maxRatingForm) => {
  return PROFILE_TYPES.find((p) => p.max_rating === maxRatingForm)?.name;
};

/**
 * Validate if exist a profile  with default name in Collection
 * @param {*} profilesResult
 *
 * @return {Array}
 */
export const validateDefaultProfileInCollection = (profilesResult) => {
  return profilesResult?.find(
    (profile) => profile.name === PROFILE_NAMES.DEFAULT,
  );
};

/**
 * get avatarId random when the user does not select an avatar image
 * @param {*} profilesResult
 *
 * @return {Array}
 */
export const getAvatarIdRandom = (avatarCollection) => {
  const keys = Object.keys(avatarCollection);
  return avatarCollection[keys[(keys.length * Math.random()) | 0]]?.id;
};
