import { createSelector } from 'reselect';

const getAppReducer = (state) => state.containers.app;
const getSessionReducer = (state) => state.session;
const getReducer = (state) => state.containers.playerEpgSection;
const getAppSectionsReducer = (state) => state.containers.appSections;
const getProfileReducer = (state) => state.containers.profileManager;

export const selectAccessToken = createSelector(
  getSessionReducer,
  (reducer) => reducer.accessToken,
);

export const selectAppSettings = createSelector(
  getAppReducer,
  (reducer) => reducer.appSettings,
);

export const selectToggleProfilesModal = createSelector(
  getAppReducer,
  (reducer) => reducer.toggleProfilesModal,
);

export const selectIsFetching = createSelector(
  getReducer,
  (reducer) => reducer.isFetching,
);

export const selectWidgetCollection = createSelector(
  getReducer,
  (reducer) => reducer.widgetCollection,
);

export const selectContent = createSelector(
  getReducer,
  (reducer) => reducer.content,
);

export const selectEpgList = createSelector(
  getReducer,
  (reducer) => reducer.epgList,
);

export const selectEpgDataByIds = createSelector(
  getReducer,
  (reducer) => reducer.epgDataByIds,
);

export const selectToggleModal = createSelector(
  getReducer,
  (reducer) => reducer.toggleModal,
);

export const selectIsPlayerEpgSection = createSelector(
  getReducer,
  (reducer) => reducer.isPlayerEpgSection,
);

export const selectError = createSelector(
  getReducer,
  (reducer) => reducer.error,
);

export const selectAuthenticated = createSelector(
  getSessionReducer,
  (reducer) => reducer.authenticated,
);

export const selectPathList = createSelector(
  getAppSectionsReducer,
  (reducer) => reducer.pathList,
);

export const selectUserUrns = createSelector(
  getProfileReducer,
  (reducer) => reducer.userUrns,
);
